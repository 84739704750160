export const environment = {
    production: false,
    brandCode: 'okohon',
    env: 'dev',
    firebase: {
        apiKey: 'AIzaSyBo2a9JfkSKY-N4qD3Q5pflrSD1RRTzVzI',
        authDomain: 'mc-app-okohon-dev.firebaseapp.com',
        projectId: 'mc-app-okohon-dev',
        storageBucket: 'mc-app-okohon-dev.appspot.com',
        messagingSenderId: '810435763591',
        appId: '1:810435763591:web:1dde271b8c0a6e86884722',
        measurementId: 'G-PSD1YZE8YZ',
    },
    hostingBaseURL: 'https://app-dev.okonomiyaki-honpo.jp',
    appScheme: 'okohon-monogatari-app-dev',
    storeURL: {
        ios: 'https://apps.apple.com/app/id1498409263',
        android: 'https://play.google.com/store/apps/details?id=jp.okonomiyaki_honpo.members',
    },
}
